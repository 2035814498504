// Simulates infinite scrolling on mobile for product categories and search results.

// Using jQuery to avoid JS bugs on mobile with click simulation.

// This function is conditioned based on the value of #is_loading in the DOM to avoid too many clicks during the request. See => generateNewBlocProd.

// The isInViewport function is used to check if the button is visible on the screen. If so, the click is simulated, otherwise not. This function is called in the click condition during scrolling.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

$(function() {
    var isclicked = false;
    var mainContainer = document.querySelector("body.category");

    if (mainContainer) {
        $(window).scroll(function() {
            var isLoading = $('#is_loading').val();
            const maxItems = document.getElementById('totalElems').value;
            const productLoaded = $('#list_item .item:not(".push")').length;
            const pagerNav = document.querySelector('.pagerNav.end_pagi');
            const button = document.querySelector('.see_more_product');

            // Cookie is created in object_index.php when "see_all_auto" is triggered on the page
            if ((getCookie('infinite_scroll') == "1")) {

                button.style.opacity = '1';

                if ($('.see_all_auto').is(':visible')) {
                    $('.see_all_auto').fadeOut(300);
                }

                if (isInViewport(button) && isLoading == 0) {
                    $('.button.see_more_product').trigger('click');
                    $('#is_loading').val('1');
                }

                if (productLoaded >= maxItems) {
                    $('#is_loading').val('0');
                    eraseCookie('infinite_scroll');
                }
            }
        });
    }

    // It means that we're on another category page and we need to remove the cookie
    if ((getCookie('infinite_scroll') == "1") && $('#initial_page').val() == "1") {
        eraseCookie('infinite_scroll');
    }
});

// Function to get a cookie by its name
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}