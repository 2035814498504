/*/ add a solid background white to the banner when site_head has position sticky /*/
var header = document.getElementById("site_head");

function onWinScroll() {
    var scrollTop = $(document).scrollTop();

    if (header.classList.contains("sticky")) {
        if (scrollTop === 0) {
            header.classList.remove("sticky");
        }
    } else {
        if (scrollTop !== 0) {
            header.classList.add("sticky");
        }
    }
}

$(window).on("scroll", onWinScroll);

onWinScroll();

// overide APP toggleAccountMenu && toggleMenu, for shad visibility
function toggleAccountMenuExtra() {
    var scrollTop = $(document).scrollTop();

    if ($('#account_menu').hasClass('active')) {
        $('#shade').addClass('user_dropdown');
        $('#site_head').addClass("sticky");
    } else if (!$('#account_menu').hasClass('active') && scrollTop !== 0) {
        $('#shade').removeClass('user_dropdown');
        $('#site_head').addClass("sticky");
    } else if (!$('#account_menu').hasClass('active') && scrollTop == 0) {
        $('#shade').removeClass('user_dropdown');
        $('#site_head').removeClass("sticky");
    }
}

function toggleMenuExtra() {
    if ($('#account_menu').hasClass('active') && $('#main_menu_btn').hasClass('menu_open')) {
        $('#shade').removeClass('user_dropdown');
    } else if ($('#account_menu').hasClass('active') && !$('#main_menu_btn').hasClass('menu_open')) {
        $('#shade').addClass('forMenu visible user_dropdown');
        $('body').addClass("noscroll");
    }
}

/*/ Add search bar icon in menu mobile top /*/
$('<div id="search_icon" class="header_section search_icon" onclick="showhide_search();"><span></span></div>').insertBefore("header .banner_nav .header_section.logo");

function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != '') {

                var force = '';
                if (uri == '/kujten.com') {
                    force = '?force';
                }

                location.href = data + force;

            }
        }
    });
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    $("#shade").addClass("visible").fadeTo("slow").on("click", function () {
        closeMultiShad(id);
    });
}

function closeMultiShad(id) {

    $('#shade').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').hasClass("visible")) {
            $('#shade').removeClass("visible");
        }

        $(this).dequeue();
    });

    if (id === "modal-personnalisation") {
        $('#shade').removeClass("modal-personnalisation");
    }
}

if (!$('.bandeauMenuAffix').length) {
    $('body').addClass('promo_off');
}

if ($('#popup_numero_retour').hasClass('open')) {
    $('body').css('border', '5px solid red');
}

/*
 * [Display Press lightbox]
 */
$(".pressImg").on("click", function () {

    "use strict";

    $("#pressLightboxVisu").html("");
    $(this).clone().appendTo("#pressLightboxVisu");
    openMultiShad("pressLightbox");
});

/* Get distance to add to search bar */
function getDistanceSearch() {
    if ($(".bandeauMenuAffix").length > 0) {
        var getBandeauHeight = $(".bandeauMenuAffix").height();

    } else {
        var getBandeauHeight = 0;
    }

    var getHeaderHeight = $("header.banner_wrapper").height();
    var getTopDistance = getBandeauHeight + getHeaderHeight;

    $(".block_top_item.search").css("top", getTopDistance - 12);
}

$(window).resize(function () {
    getDistanceSearch();
});

/*/ Toggle new search bar v2/*/
function showhide_search() {
    $('.block_top_item #form_recherche').slideToggle().toggleClass('activated');

    var topSearch = $('.block_top_item #form_recherche').hasClass('activated');

    if (topSearch) {
        $("#shade").addClass('visible');
        $('body').addClass('searching').removeClass('search_hidden');
        $('.block_top_item #form_recherche').addClass('activated');
        getDistanceSearch();
    } else {
        $("#shade").removeClass('visible');
        $('body').removeClass('searching').addClass('search_hidden');
        $('.block_top_item #form_recherche').removeClass('activated');
    }

    if ($('#account_menu').hasClass('active') && topSearch) {
        $('#shade').removeClass('user_dropdown forMenu');
    }
}

// Close recherche mobile by shade
$(document).on('click', '#shade.visible', function () {
    $("body").removeClass("noscroll");
    $(".block_top_item #form_recherche").removeClass("activated");
    $("#shade").removeClass('visible');
    $(".homepage").addClass('search_hidden');
    $(".block_top_item #form_recherche").css('display', 'none');

    if ($('#account_menu').hasClass('active') && !$('.block_top_item #form_recherche').hasClass('activated')) {
        $('#shade').addClass('forMenu visible user_dropdown');
    }
});

// FAQ
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1)
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({
        scrollTop: $(".faq_question_" + question_id + "").offset().top - 100
    }, 1000);

}

var menu_goto_type = ['menu', 'categ', 'sscateg', 'objet']; // const

function resizeAfterHideBanner() {
    if (getCookie("cookie_header_delivery") == "accepted") {
        $(".banner_wrapper").css('top', 0);
        var top = $(".banner_wrapper").height();
        $("#ariane_home").css('top', top + 'px');
        $(".drawer.left").css('top', top + 'px');
        $(".drawer.left").css('height', 'calc(100% - ' + top + 'px)');
    }

    getDistanceSearch();
}

/**
 * Check if 'element' is in viewport
 */
function isInViewport(element) {

    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {

    if ($('.category .prodItemSlider, .searchItemSlider, .dynasearchwrapper .prodItemSlider').length) {

        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) { // Toggle view is triggerred

            setTimeout(function () {

                $('.category .prodItemSlider, .searchItemSlider, .dynasearchwrapper .prodItemSlider').each(function () {

                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll

            $('.category .prodItemSlider').each(function () {

                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) { // Prevent initialized sliders to go back to the first frame during scroll

                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '.itemSliderPagination',
                    });

                    $(this).addClass('initialized');
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) {
                    new Swiper($(this), {
                        slidesPerView: 1,
                        loop: true,
                        roundLengths: true,
                        observer: true,
                        observeParents: true,
                        preloadImages: false,
                        lazyLoading: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true,
                        pagination: '.itemSliderPagination',
                    });

                    $(this).addClass('initialized');
                }
            });

            setTimeout(() => {
                $('.dynasearchwrapper .prodItemSlider').each(function () {
                    if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) {
                        new Swiper($(this), {
                            roundLengths: true,
                            slidesPerView: 1,
                            loop: true,
                            preloadImages: false,
                            lazyLoading: swiperLazy,
                            observer: true,
                            observeParents: true,
                            pagination: '.itemSliderPagination',
                        });

                        $(this).addClass('initialized');
                    }
                });
            }, 500);
        }
    }
}

/**
 * Load global .item_container slider initialization on window scroll
 */
window.addEventListener("scroll", function () {

    initItemSlider(false);
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else if (url.indexOf("conseil") > -1) {
            if ($(".conseil_home").hasClass('actif')) {
                window.history.back();
            } else {
                $(".item_conseil").removeClass("actif");
                $(".conseil_home").addClass("actif");
            }
        } else {
            window.history.back();
        }
    }
}

// Lookbook
function loadModboxProduitsAssocies(look_id) {

    var $element = $('#lightbox_achat_express_look');
    $element.find('.main_look_swiper').hide();
    $element.find('.loader_lookbook').addClass('loading');

    // Achat express v2
    ajax_achat_express_look(look_id);

    $element.addClass('open');

    $element.find('.close_btn').on('click', function () {
        $('body').css('position', 'initial');
        $element.removeClass('open');
        $("#shade").css({'opacity': '0', 'z-index': '0'}).hide();
        $("#item_added").removeClass('open');
    });
    if ($element.hasClass('open')) {
        $("#shade").css({'opacity': '1', 'z-index': '103'}).show();
        $('body').css('position', 'fixed');
    }


    $('#item_added .modbox_buttons button.cancel').on('click', function () {
        $("#shade").css('opacity', '0').hide();
        $('body').css('position', 'initial');
    });

    $("#shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {

        $("#shade").css({'opacity': '0', 'z-index': '0'}).hide();
        $('body').css('position', 'initial');
        $element.removeClass('open');
        $("#item_added").removeClass('open');
    });
}

// Override loadprodut TG swiper refresh with Kujten swiper infos
function refreshSliderAchatExpress(id_swiper) {
    id_swiper = 'homeSwiper.swiper-container';

    setTimeout(function () {
        new Swiper('#' + id_swiper, {
            slidesPerView: 1.5,
            centeredSlides: true,
            spaceBetween: 15,
            lazyLoading: true,
            loop: true
        });
    }, 0);
}

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {


    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;
    var numDIVObjet = $("#popup_numero_retour");

    openMultiShad('popup_numero_retour');

    // Affichage de la popup de retour

    $('#popup_numero_retour').addClass('open');

    $("#return_products_form_wrapper").overlayScrollbars({});

    checkModalOpen(numDIVObjet);

    /*******************************/

}

/* vérifier si le modal est visible */
function checkModalOpen(modalElm) {
    var modelOpen = modalElm.hasClass("open");

    if (modelOpen) {
        modalElm.closest('body.order').addClass('modal-open');
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    var has_errors = false;

    $(formId + " .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form').serialize();
        data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}


/* Page FP */
$(document).ready(function () {
    if ($('body.product_page .bottom_holder .eclat_product.rect').length < 1) {
        $('body.product_page #wrapper_product_name').addClass('pushtop');
    }
    if ($('.mobile .jqzoom .product_picture').length < 2) {
        $('.mobile #zoom_container .swiper-button-prev').hide();
        $('.mobile #zoom_container .swiper-button-next').hide();
    }
});

function ajax_achat_express_look(look_id) {

    $.ajax({
        type: 'get',
        url: path_relative_root + 'ajax_achat_express_look.php?look_id=' + look_id,
        success: function (response) {
            var $lighbox = $('#lightbox_achat_express_look');

            $('#content_achat_express_look').html(response);

            if ($("#look_main_swiper .swiper-slide").length > 1) {

                setTimeout(function () {
                    var look_main_swiper = new Swiper("#look_main_swiper", {
                        centeredSlides: true,
                        slidesPerView: 1,
                        spaceBetween: 25,
                        nextButton: '.swiper-button-next',
                        prevButton: '.swiper-button-prev',
                    });
                }, 600);
            } else {
                $("#look_main_swiper").addClass('noSwiper');
            }

            $lighbox.find('.loader_lookbook').removeClass('loading');
            $lighbox.find('.main_look_swiper').show();
        }
    });
}

var assoSwiperParams = {
    loop: true,
    slidesPerView: 1,
    loopAdditionalSlides: 1,
};

function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#prod_couleur");
    var tailleProd = $("#prod_taille");

    if (couleurProd.length) {
        couleurProd = $("#prod_couleur").val();
    } else {
        couleurProd = 0;
    }

    if (tailleProd.length) {
        tailleProd = $("#prod_taille").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    var btn = $("#addToWishlistButton");

    if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = btn.attr("onclick");
    }

    if (in_wishlist) {
        btn.attr("onclick", "window.location.assign(path_relative_root + create_link('wishlist'))");
        btn.removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

$(".menu_part.top .close_menu").insertBefore(".menu_part.top");

// v2 code
function toggleViewMobile() {
    $('div.toggleNumObjects span').toggleClass('actif');

    if ($("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.item_container').addClass('full');
        createCookie('nb_produits', 1, 1);
    } else {
        $('div.item_container').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie('nb_produits', 2);
    }
    initItemSlider(true);
}

// Toggles filters submenu visibility mobile
function toggleFilters() {
    if ($(this).find('.selected_options').hasClass('actif')) {
        $(this).find('.selected_options').removeClass('actif');
        $(this).find('.filter_name span').removeClass('actif');
    } else {
        $('.selected_options').removeClass('actif');
        $('.filter_name span').removeClass('actif');
        $(this).find('.selected_options').addClass('actif');
        $(this).find('.filter_name span').addClass('actif');
    }
}

function searchV2Shad() {
    setTimeout(function () {
        $("#shade").removeClass('visible');
    }, 1500);
}

function hideAchatExpress() {
    $("#shade").removeClass("visible").removeClass("isExpress");
    $(this.form).find('.achat_express').hide();
    $(this.form).find('.actif').removeClass('actif');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }

    // remove clone achat express
    removeCloneAchatExpress();

    if ($('body.product_page').length && $('.ranges_carousel_wrapper.associations').length) {
        $('.ranges_carousel_wrapper.associations .assoSlider .swiper-wrapper').removeClass('stop_transform').css({"left": ""});
    }
}

function removeCloneAchatExpress() {
    // remove clone achat express FP
    if ($('body.product_page').length) {
        $('.associations + .rollover.achat_express').remove();
    }

    // remove clone achat express TG HP
    if ($('body.homepage').length) {
        $('.tete-gondole + .rollover.achat_express').remove();
    }
}

function cloneAchatExpress() {
    // clone Rollover achat express ( sortir du swiper slide)
    if ($('body').hasClass('product_page')) {
        $('.product_page .bloc_achat_express').on('click', function () {
            $(this).prev().clone().insertBefore('.ranges');
            $('.product_page .wrap_rolloverproduit .rollover.achat_express').hide();
        });
    }

    if ($('body').hasClass('homepage')) {
        $('.homepage .bloc_achat_express').on('click', function () {
            $(this).prev().clone().insertAfter('.tete-gondole');
            $('.homepage .wrap_rolloverproduit .rollover.achat_express').hide();
        });
    }
}


// Initialisation des JS pour la recherche V2
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;

    var type = document.getElementById('type_tri').value;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;


    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type == 'product') {
        generateNewBlocProd(type, idObj, bloc_page, null, null, fromScrollBottom, fromScrollTop, true);
    } else {

        generateNewBlocSearch(document.getElementById('nb_prod').value, bloc_page, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    if (document.getElementById('filters_form')) {
        var form = document.getElementById('filters_form'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),

            page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                updateProductsPagination();
            }
        });
    }
}

function updateProductsPagination() {

    var form = document.getElementById('filters_form'),
        total_element = $('#totalElems').val(),
        productLoaded = $('.page_rayon .item_container').length;
    percentLoaded = (productLoaded * 100 / parseInt(total_element));

    $('.loader_scroll .see_more_product span:not(.loadedGauge)').html(Translator.translate('see_more_products') + ' (' + productLoaded + ' / ' + total_element + ')');
    // Graduates background color as products are loaded
    $('.loadedGauge').css('width', (percentLoaded + '%'));


    if (productLoaded >= total_element) {
        if (getCookie('infinite_scroll') == "1") $('#infinite_scroll').val('0');
        $('.loader_scroll').hide();
        $('.see_all_auto').hide();
    } else {
        $('.loader_scroll').show();
        if (getCookie('infinite_scroll') != '1') $('.see_all_auto').show();
    }

    if ($('.loader_scroll').hasClass('loading')) $('.loader_scroll').removeClass('loading');
}

function generateNewBlocFilter(type, idObj, page, nb_product, btn, typeFiltre, id) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        value = getInputVal.bind(this),
        output = {},
        tmpoutput = {},
        is_from_page = false,
        key;

    var thisform = form;

    // Get all filters
    form = Array.prototype.slice.call(form.elements, 0);
    var keys = new Array();

    if (use_new_filter_url) {
        output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    } else {
        // Build an object to store active filters
        form.forEach(function (elm) {
            if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                    if (output[elm.name] === undefined) {
                        output[elm.name] = [];
                    }
                    output[elm.name].push(parseFloat(elm.value));
                }
                keys[elm.name] = 1;
            }
        });
        // Convert filters arrays to strings
        for (key in output) {

            if (output.hasOwnProperty(key)) {

                if (output[key].constructor === Array) {

                    output[key] = output[key].join('~');
                }
            }
        }
        if ($(".filter_date").length > 0) {

            output.multi_object = 'true';
            output.id_category_super = id_category_super;

            if (typeof (output['date']) == 'undefined') {
                output.date = 4;
            } else {
                if (output['date'] == 4)
                    output['date'] = tmp_date;
            }
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }

        if (nb_product !== undefined) {

            this.nb_prod.value = nb_product;
        } else {

            nb_product = this.nb_prod.value;
        }

        // Add misc values to output object
        output.page = this.page.value;
        output.type = type;
        output.parentPath = path_relative_root;
        output.idObj = idObj;
        output.nb_prod = nb_product;
        output.is_page = is_from_page;
        output.nb_tot_prod = this.totalElems.value;
        output.typeFiltre = typeFiltre;
        output.current_page = page + 1;

    }

    /** LEGACY **/

        //Recuperation du trie par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        var tri = res[1][0];
    }

    /** END LEGACY **/

    if (!tri) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_change_filtre_produit.php',
        type: 'get',
        data: output,
        success: function (response) {

            var resultats = jQuery.parseJSON(response);

            var typeFilter = resultats.filter;
            var array_size = resultats.size;
            var array_colors = resultats.color;
            var array_brand = resultats.brand;
            var array_eclat = resultats.eclat;
            var array_store = resultats.store;

            if (typeFilter == 'sscat' && $('.container_products_lookbook').length == 0) {
                if (array_colors.length == 0 || array_size.length == 0) {
                    changeajaxfilterRayon(thisform, 'all', id, true);
                    return true;
                }
            }

            if (typeFilter != 'taille') {
                generateListFilter('taille', array_size, true);
            }

            if (typeFilter != 'couleur') {
                generateListFilter('couleur', array_colors, true);
            }

            if (typeFilter != 'brand') {
                generateListFilter('promo', array_brand, true);
            }

            if (typeFilter != 'eclat') {
                generateListFilter('eclat', array_eclat, true);
            }

            if (typeFilter != 'store') {
                generateListFilter('store', array_store, true);
            }

            /* Nombre de produits */
            // Get all filters
            form = Array.prototype.slice.call(thisform.elements, 0);
            // Build an object to store active filters
            form.forEach(function (elm) {
                if ((elm.type === 'checkbox' || elm.type === 'radio')) {

                    if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                        if (tmpoutput[elm.name] === undefined) {
                            tmpoutput[elm.name] = [];
                        }

                        if (elm.name == 'charact') {
                            tmpoutput[elm.name].push(elm.value);
                        } else {
                            tmpoutput[elm.name].push(parseFloat(elm.value));
                        }
                    }
                }
            });

            // Convert filters arrays to strings
            for (key in keys) {
                if (tmpoutput.hasOwnProperty(key)) {
                    output[key] = tmpoutput[key].join('~');
                } else {
                    delete output[key];
                }
            }

            if ($(".filter_date").length > 0) {

                output.multi_object = 'true';
                output.id_category_super = id_category_super;

                if (typeof (output['date']) == 'undefined') {
                    output.date = 4;
                } else {
                    if (output['date'] == 4)
                        output['date'] = tmp_date;
                }
            } else {
                if (output['date'] == 4) {
                    output['date'] = tmp_date;
                }
            }

            bloc_prod_xhr = $.ajax({
                url: path_relative_root + 'ajax_reload_products.php',
                type: 'get',
                data: output,
                success: function (res) {
                    var new_elm = document.createElement('div'),
                        old_elm = document.getElementById('scroll_items'),
                        content, itm_length;

                    res = res.split('><><');

                    content = res[0].trim();

                    if (res[1] !== '') {
                        itm_length = parseFloat(res[1]);

                        //Permet d'actualiser lors de l'application d'un filtre, le nombre de produits total restants après application
                        //Info acutalisée en haut du filters_menu - potentiellement en display:none;
                        document.getElementById('nbr_items').innerHTML = itm_length;
                        document.getElementById('totalElems').value = itm_length;

                        if (window.lazyload) {

                            lazyload.init();
                        }
                    }
                },
                complete: function () {
                    var evt = new CustomEvent("generateNewBlocFilter_complete");
                    window.dispatchEvent(evt);
                }
            });
            $('#is_loading').val('0');
        }

    });

}

function generateNewBlocProd(type, idObj, page, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    if ($('.loader_scroll').length) {
        $('.loader_scroll').addClass('loading');
    }
    var form = (this instanceof HTMLFormElement) ? this : $('#filters_form'),
        value = getInputVal.bind(this),
        output = {},
        is_from_page = false,
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        type = type || $('#type').val(),
        idObj = idObj || parseFloat($('#idObj').val()),
        startPage = parseInt($('#page_start').val()),
        key;

    // Get all filters
    output = triggerFilter(page, type, idObj, nb_product, 'filters_form', fromPagination);


    if (fromScrollBottom && !fromScrollTop) {
        $("#page_start").val(startPage + 1);

        if ($("#page_start").val() >= 1) {
            $("#initial_page").val(page + 1);
        }
    }

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }


    // Convert filters arrays to strings
    if ($(".filter_date").length > 0) {
        output.multi_object = 'true';
        output.id_category_super = id_category_super;

        if (typeof (output['date']) == 'undefined') {
            output.date = 4;
        } else {
            if (output['date'] == 4) {
                output['date'] = tmp_date;
            }
        }
    } else {
        if (output['date'] == 4) {
            output['date'] = tmp_date;
        }
    }

    if (nb_product !== undefined) {
        this.nb_prod.value = nb_product;
    } else {
        nb_product = this.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;

    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    // Add misc values to output object
    output.page = this.page.value;

    if (page !== undefined && page !== 'all') {
        if (page != 0) {
            output.page = $('.item_container').length;
        }
    }
    output.handle_push = 'false';
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.nb_prod = nb_product;
    output.is_page = is_from_page;
    output.nb_tot_prod = this.totalElems.value;
    output.object_id = this.object_id.value;

    if (typeof id_category_super != 'undefined') {
        output.id_category_super = id_category_super;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    /** LEGACY **/
        // Recovery of sorting by price (top down or bottom up) which passes, unfortunately, by the url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // If there is an order in the prices we load the id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (page === 'all') {
        /** LEGACY **/
        hash = location.hash.substr(1);
        if ((/i=[0-9]+/).test(hash)) {
            // Vu que le header est en absolute on le sort du calcul
            var marginTop = $('.banner_wrapper').height() + 10,
                $elem = $('#' + hash.replace('=', '')),
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {
                $(window).scrollTop(top);
            }, 1000);
        }

        /** END LEGACY **/
    } else {
        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {

                var old_elm = document.querySelector('.list_item');
                var content, itm_length;

                if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();

                itm_length = parseFloat(res[1]);

                if ($('.rayonCategSlider').length && output.object_id) {

                    $('.rayonCategSlider .menuitem').removeClass('actif');
                    $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                    var rayonInitialSlide;

                    $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                        if ($(obj).hasClass('actif')) {
                            rayonInitialSlide = i;
                        }
                    });

                    rayonCategSlider.slideTo(rayonInitialSlide);
                } else {
                    $('.rayonCategSlider .menuitem').removeClass('actif');
                }
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                // spécifique à Kujten qui n'a que la langue EN en plus du fr
                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                // specifique à Kujten qui veut que le texte SEO n'existe que en page 1 et pas ailleurs
                changeSeoElement(prev, hrefPrev, commentPrev, 'prev', seo, $("#lightbox_achat_express_v2"), $(".textSeo"));
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (!fromPagination) {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('nbr_items').innerHTML = itm_length;
                    document.getElementById('totalElems').value = itm_length;

                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (one_product) {
                        $('div.item_container').addClass('full');
                    }

                    if (parseFloat($('#filters_menu').css('right')) === 0) {
                        toggleMenu('filters', 'right');
                    }

                    if (window.lazyload || typeof lazyload != 'undefined') {
                        lazyload.init();
                    }
                }
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                setTimeout(function () {
                    if ($(btn).closest('.drawer').length && $(btn).closest('.drawer').attr('id')) {
                        var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');
                        toggleMenu(menuId);
                    }
                }, 200);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                updateProductsPagination();
            }
        });
    }
}

function generateNewBlocSearch(nb_product, page, btn, fromScrollBottom, fromScrollTop) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        is_from_page = false,
        key;

    if (page == 0) {
        if (form) {
            form.initial_page.value = page + 1;
            form.scroll_origin.value = page + 1;
        } else {
            $('#filters_form #initial_page').val(page + 1)
            $('#filters_form [name="scroll_origin"]').val(page + 1)
        }
    }

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.value !== '') {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }
                output[elm.name].push(elm.value);
            }
        } else if (elm.type === 'hidden') {
            if (elm.value !== '') {
                output[elm.name] = elm.value;
            }
        }
    });

    if (fromScrollTop || fromScrollBottom) {
        $(".loader_scroll").css({
            display: 'block'
        });
        $('#is_loading').val('1');
    }

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join('~');
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== 'all') {

        form.page.value = output.page = page * nb_product;
        is_from_page = true;
    }

    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix =
            $("#amount1").val() +
            "~" +
            $("#amount2").val();
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_reload_search'),
        type: 'get',
        data: output,
        success: function (res) {

            var new_elm = document.createElement('div'),
                old_elm = document.querySelector('.list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            if (content !== '') {

                if (!fromScrollBottom && !fromScrollTop) {
                    new_elm.className = 'list_item';
                    new_elm.innerHTML = content;
                    old_elm = document.querySelector('#scroll_items');
                    $(old_elm).find('list_item').remove();
                }

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".loader_scroll").show();
                } else {
                    backToTop();
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;

                paginate(page + 1);

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.appendChild(new_elm);
                }

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else if (fromScrollBottom) {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$() {}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {
                    }
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {
                    }
                }

                /** END LEGACY **/

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload) {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            // $(".loader_scroll").fadeTo("fast", 0);

            setTimeout(function () {
                var menuId = $(btn).closest('.drawer').attr('id').replace('_menu', '');

                toggleMenu(menuId);
            }, 200);

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();

                    target.each(function () {
                        $(this).css({minHeight: targetHeight});
                    })
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#initial_page").val(page + 1);
                $(".loader_scroll").hide();
            }

            if ($('.see_more_product.loading').length) {
                $('.see_more_product').removeClass('loading');
            }
        }
    });
}

// Giftcard form

function openFormCard(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
        $("#" + id).addClass("opened_form");
        $('.giftCard_btn').hide();
    });
}

$(function () {

    if ($('#subscribe_general').length) {
        var container = $('.newsletter.satellite_mobile').find('.newsletter_option.form_item')
        $(container[0]).append('<span class="w-newsletter-next-label">' + Translator.translate('optin_news_general_text_next') + '</span>')
    }

    // Page rayon- achat express, pas besoin le button valider
    if ($('.site_contents_wrapper .container_products .list_item .wrap_rolloverproduit .bloc_achat_express .button.valid').length) {
        $('.site_contents_wrapper .container_products .list_item .wrap_rolloverproduit .bloc_achat_express .button.valid').css('display', 'none');
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function () {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function () {
        if ($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 300);
        }
    });

    if ($(".swiper-slide", "#sliderBando2").length > 1) {
        var bandoHeader = new Swiper("#sliderBando2", {
            paginationClickable: true,
            preloadImages: true,
            lazyLoading: true,
            loop: true,
            cube: {
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
            },
            direction: "vertical",
            autoplay: 3000,
            speed: 2200,
            autoplayDisableOnInteraction: false
        });
    }

    if ($('#super_home_swiper.swiper-container').length) {
        new Swiper('#super_home_swiper.swiper-container', {
            loop: true,
            slidesPerView: 1,
            speed: 2000,
            autoplay: 5000,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }

    setTimeout(resizeAfterHideBanner, 500);

    if ($('#scroll_items .item_container').hasClass('full')) {
        $('.item.push').addClass("full");
    }

    if ($('.rayonCategSlider').length) {

        var rayonInitialSlide;

        $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('actif')) {
                rayonInitialSlide = i;
            }
        });

        var rayonCategSlider = new Swiper('.rayonCategSlider', {
            slidesPerView: 'auto',
            initialSlide: rayonInitialSlide,
            centeredSlides: true
        });
    }

    socialSlider = new Swiper('#socialSwiper', {
        slidesPerView: 1.5,
        spaceBetween: 15,
        loop: true,
        centeredSlides: true
    });

    var pushCategSlider = new Swiper('.pushCategSlider', {
        slidesPerView: 'auto',
        freeMode: true
    });

    var metaSwiper = new Swiper(".metaSwiper", {
        slidesPerView: 1.5,
        spaceBetween: 15,
        loop: true,
        centeredSlides: true
    });

    var metaFilterSwiper = new Swiper(".metaFilter", {
        slidesPerView: 'auto',
        spaceBetween: 50,
    });

    var catPushSwiper = new Swiper(".catPush", {
        slidesPerView: 1.5,
        spaceBetween: 15,
        centeredSlides: true
    });

    var pressSwiper = new Swiper(".historyMainNav", {
        slidesPerView: 1.5,
        spaceBetween: 15,
        loop: true,
        centeredSlides: true
    });

    // SLIDER HOME
    if ($('#super_home_swiper').length) {
        var id = 'super_home_swiper';
        // store last played video to put in pause when slide
        var lastPlayedVideo = '';

        //init and store all youtube players
        var youtubePlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.youtube_video').length >= 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);

            window.onYouTubeIframeAPIReady = function () {
                $('#' + id + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        //add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    swiperHome.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });

            };
        }

        // Cas Video Vimeo
        var vimeoPlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.vimeo_video').length >= 1) {
            $('#' + id + ' .swiper-slide iframe.vimeo_video').each(function () {
                var videoId = $(this).attr('id');
                var playerVimeo = new Vimeo.Player($(this));

                //add a listener to start the slider autoplay again
                playerVimeo.on('ended', function () {
                    swiperHome.autoplay.start();
                });
                vimeoPlayers[videoId] = playerVimeo;
            });
        }

        //init swiper
        var swiperHome = new Swiper('#' + id, {
            slidesPerView: 1,
            speed: 1500,
            autoplay: 3000,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets'
            },
            on: {
                slideChangeTransitionEnd: function () {
                    //when a video is displayed
                    //stop the old one
                    //plays the new one
                    //stop the slider until the end
                    //store the video as last played
                    if ($('#' + id + ' .swiper-slide-active').find('iframe.vimeo_video').length) {
                        swiperHome.autoplay.stop();
                        $('#' + id + ' .swiper-slide-active').find('iframe.vimeo_video').each(function () {
                            var videoId = $(this).attr('id');
                            setLastVideoPause(videoId);
                            vimeoPlayers[videoId].play();
                            lastPlayedVideo = videoId;
                        });
                    } else if ($('#' + id + ' .swiper-slide-active').find('iframe.youtube_video').length) {
                        swiperHome.autoplay.stop();
                        $('#' + id + ' .swiper-slide-active').find('iframe.youtube_video').each(function () {
                            var videoId = $(this).attr('id');
                            setLastVideoPause(videoId);
                            youtubePlayers[videoId].playVideo();
                            lastPlayedVideo = videoId;
                        });
                    }
                }
            }
        });
    }

    //swiper home module
    if ($('.home-module.four-images').length) {
        setTimeout(function () {
            new Swiper('.home-module.four-images.swiper-container', {
                loop: true,
                loopAdditionalSlides: 2,
                loopedSlides: 4,
                preloadImages: false,
                lazy: true,
                lazyLoading: true,
                slidesPerView: 1.2,
                spaceBetween: 10,
                roundLengths: true,
            });

        }, 500);
    }

    //with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if (lastPlayedVideo != '') {
            if ($('#' + lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            } else if ($('#' + lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }

    if ($("body").hasClass("product_page")) {

        var isTop = true;

        $(document).scroll(function () {
            if ($(window).scrollTop() === 0 && !isTop) {

                isTop = true;
                $(".banner_wrapper").fadeTo("fast", 0).css('pointer-events', 'none');
                if ($('.bandeauMenuAffix:visible')) {
                    $(".bandeauMenuAffix").fadeTo("fast", 0).css('pointer-events', 'none');
                }
                $("#ariane_home").fadeTo("fast", 0).css('pointer-events', 'none');
            } else if ($(window).scrollTop() > 1 && isTop) {

                isTop = false;
                $(".banner_wrapper").fadeTo("fast", 1).css('pointer-events', 'auto');
                if ($('.bandeauMenuAffix:visible')) {
                    $(".bandeauMenuAffix").fadeTo("fast", 1).css('pointer-events', 'auto');
                }
                $("#ariane_home").fadeTo("fast", 1).css('pointer-events', 'auto');
            }
        });
    }

    if ($("body").hasClass("order_cgv") || $("body").hasClass("footer_legalmentions") || $("body").hasClass("satellite_politique-confidentialite") || $("body").hasClass("category")) {
        $(".accountTitleHolder").insertBefore($("#ariane_home"));
    }

    /*******************************************/
    /*NAV SATELLITE*/
    /*******************************************/
    if ($('.satellite_nav_bar').length) {

        $('.satellite_nav_bar').on('change', function (e) {

            var tab = $('.satellite_nav_bar option:selected');
            $(".tabsatellite").removeClass("active");
            $('.satellite_tab').removeClass('actif')
            $('#' + tab.attr('data-tab')).addClass("actif").trigger('actifAdded');
            tab.addClass("active");

            /*SAVOIR FAIRE*/
            if ($('#tricotage').length) {
                var satelliteSwiper;

                satelliteSwiper = new Swiper(".savoir_tricotage_container ", {
                    slidesPerView: "auto",
                    centeredSlides: true
                });
            }
        });
    }


    //page contact faq
    $('.item_title').click(function (e) {

        'use strict';

        var $this = $(this).parent().find('.block_question');
        $('.contact_item .block_question').not($this).slideUp();
        $('.contact_item').removeClass("active");

        $this.slideToggle(function () {
            $this.parent().toggleClass('active', $(this).is(':visible'));
        });
    });

    $('.question_title').click(function (e) {

        var answer = $(this).next($('.block_answer'));

        if ($(this).hasClass('active')) {

            answer.slideUp(300);
            $(this).removeClass('active');
        } else {

            $('.question_title').removeClass('active');
            $('.block_answer').slideUp(300);
            answer.slideDown(300);
            $(this).addClass('active');
        }
    });

    /*******************************************/
    /* FIXED ARIANE ON SCROLL */
    /*******************************************/

    // Check if we're on product page
    if ($('.product_page').length) {
        var arianeHome = $('.product_page #ariane_home');
        var bannerHeight = $('#site_head .banner_wrapper').outerHeight();

        if ($('.bandeauMenuAffix:visible')) {
            var bandeauMenuHeight = $('.bandeauMenuAffix').outerHeight();
        } else {
            var bandeauMenuHeight = 0;
        }

        // Subtract 9 pixels from the calculated value
        var topValue = bannerHeight + bandeauMenuHeight - 9;

        arianeHome.css({
            "top": topValue,
            'transition': 'none',
        })
    }

    /* Pas de scroll sur le body quand le lightbox est ouvert */

    adjust_header_padding();

    window.onresize = function (event) {
        adjust_header_padding();
    };

    function adjust_header_padding(time_delay = 500) {
        setTimeout(function () {
            if ($('.bandeauMenuAffix:visible') && $('.bandeauMenuAffix').length) {
                var header_height = $('.bandeauMenuAffix').outerHeight();
            } else {
                var header_height = 0;
            }
            header_height += $('.banner_wrapper').height();
            $('body').css('padding-top', header_height + 'px');
        }, time_delay);
    }

    // adjust banner_nav when closing preheader
    $('.bandeauMenuAffix .closeBtn').on('click tap touch', function () {
        adjust_header_padding(0);
        getDistanceSearch();
    });

    /**
     * Init .item_container slider on page loaded
     */
    initItemSlider(false);

    // Init des swiper si on rafraichi la page de recherche
    if ($('.wrap_search_field #search_home').val().length >= 3) {
        initItemSlider(false);
    }

    $("body").on('click tap touch', '.bloc_achat_express', function () {
        setTimeout(function () {
            if ($('.rollover.achat_express').is(":visible") && !$("#shade").hasClass('visible')) {
                $("#shade").addClass('isExpress visible');
            }
        }, 150);
    });

    $("body").on('click tap touch', '.rollover.achat_express .preview_color', function () {
        if ($('.wrap_rolloverproduit').length && $('.productColorFieldset.actif').length) {
            setTimeout(function () {
                $('.productColorFieldset.actif').parent().parent().hide();
                $('.productColorFieldset.actif').removeClass('actif');
                $("#shade").removeClass('isExpress visible');
                // remove clone achat express
                removeCloneAchatExpress();
            }, 100);
        }
    });

    // ajaxComplete trigger cloneAchatExpress();
    if ($('body').hasClass('product_page') || $('body').hasClass('homepage')) {
        $(document).ajaxComplete(function () {
            cloneAchatExpress();
        });
    }

    // GifCard

    $("#openOrderGiftCard").click(function () {
        var cgvLink = "<div class='cgv_giftcard'><a href='" + path_relative_root + create_link('order_cgv') + "'>" + Translator.translate('giftcard_cgv') + "</a><div>";

        // change title
        $("section.pageGiftCard__header div.headerTitles h2").text(Translator.translate('giftcard_offer_it'));
        // change css of title
        $("section.pageGiftCard__header").addClass("acheter_cart_cadeau");
        // hide first section
        $(".pageGiftCard__main--infos").addClass("is-hidden");
        // increase height on pagegiftcard
        $(".pageGiftCard").addClass("acheter_cart_cadeau");
        // add lightbox
        $("#lightboxOrderGiftCard").after(cgvLink);
        // add cgv link
        $('#step3').after(cgvLink);
        // change maxlength on text area
        $("#s_msgPerso").attr('maxlength', '120');
    });

    $(".pageGiftCard__main--infos .giftCard_btn #showAmount").click(function () {
        if ($('.pageGiftCard__main--amountinfos').hasClass('logged')) {
            $(".pageGiftCard__main--amountinfos").removeClass("is-hidden");
            $(".pageGiftCard__main--amountinfos .showAmountBtn").removeClass("is-hidden");
        }
    });

    // Remove .stop_transform class from TG .associations swiper when color has changed (On product page only)
    $(window).on("loadProduct_success", function (event) { // Custom event listener triggered when color has been changed succesfully
        if ($('body.product_page').length && $('.ranges_carousel_wrapper.associations').length) {
            $('.ranges_carousel_wrapper.associations .assoSlider .swiper-wrapper').removeClass('stop_transform').css({"left": ""});
            $('.ranges_carousel_wrapper.associations .assoSlider .swiper-slide').addClass('reloadProduct');
        }
    });

    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    $("#shad, #close_numero_retour").on("click", function () {
        $("#shad").css('display', 'none');
        $("#popup_numero_retour").css('display', 'none');
    });
});


function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("_notLogged")) {
        // redirects to idenfication when not logged
        location.href = path_relative_root + create_link('order_identification') + '?from=giftcard';
    } else {
        $(".pageGiftCard__main--amountinfos").toggleClass('is-hidden');
        $(".pageGiftCard__main--amountinfos .showAmountBtn").toggleClass("is-hidden");

        if ($("#shade").css("display") === "block") {
            // when return button clicked (pop-up)
            $('#shade').fadeTo("fast", 0, function () {
                $('#shade').css('display', 'none');
                $(".pageGiftCard__main--amountinfos").addClass("is-hidden");
            });
        } else {
            // add shad after idenfication
            $("#shade").css("display", "block").fadeTo("fast", 0.8).on("click", function () {
                closeGiftCardPopup();
            });
        }
    }
};

function closeGiftCardPopup() {
    // when clicking outside pop-up
    $('#shade').off('click');
    $('#shade').fadeTo("fast", 0, function () {
        $('#shade').css('display', 'none');
        $(".pageGiftCard__main--amountinfos").addClass("is-hidden");
    });
};

function disableStepZero() {
    $('.giftcardTemplate').prop('disabled', $('#step3').css('display') === 'block');
}

function openColorAchatExpressV2() {
    setTimeout(() => {
        var $div_color = $(this.form).find('.productColorFieldset');
        var $div_size = $(this.form).find('.productSizeFieldset');
        var div_color_actif = $div_color.hasClass('actif');
        var lightbox = $(this.form).find('.achat_express');
        var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');
        var $div_alert_stock = $(this.form).find('.bloc_add_alert');

        if ($('body.product_page').length && $('.ranges_carousel_wrapper.associations').length) {
            var parent_block = $(this.form).closest('.swiper-wrapper');
            var move_left = $(parent_block).css('transform').split(',')[4];
        }

        $div_alert_stock.hide();

        $('.achat_express').hide();
        $('.productColorFieldset.actif').removeClass('actif');
        $('.productSizeFieldset.actif').removeClass('actif');

        if (lightBoxAlertStock) {
            lightbox.addClass('displayColors');
        }

        if (div_color_actif) {
            $(this.form).find('.achat_express').hide();
            $div_color.removeClass('actif');

        } else {
            $(this.form).find('.achat_express').show();
            $div_color.addClass('actif');
            $div_size.removeClass('actif');

            $("#shade").addClass("isExpress").addClass("visible");
            $("#shade").on("click touch", function () {
                $("#shade").removeClass("isExpress").removeClass("visible");
                $div_color.removeClass("actif");
                lightbox.hide();

                if ($('body.product_page').length && $('.ranges_carousel_wrapper.associations').length) {
                    $('.ranges_carousel_wrapper.associations .assoSlider .swiper-wrapper').removeClass('stop_transform').css({"left": ""});
                }
            });
        }

        openMultiShad();

        // Stop transform on the swiper when switch color (achat_express) is activated
        if ($('body.product_page').length && $('.ranges_carousel_wrapper.associations').length) {
            $('.ranges_carousel_wrapper.associations .assoSlider .swiper-wrapper').addClass('stop_transform').css({"left": move_left + "px"});
        }
    }, 250);
};

if (readCookie('nb_produits') == 1 || (readCookie('nb_produits') != 2 && $("#oneProduct").hasClass('actif'))) {
    $('div.item_container').addClass('full');
    $('div.toggleNumObjects span').removeClass('actif');
    $(".oneProduct").addClass('actif');
} else if (readCookie('nb_produits') == 2) {
    $('div.item_container').removeClass('full');
    $('div.toggleNumObjects span').removeClass('actif');
    $(".twoProducts").addClass('actif');
}

function hideButtonGiftCardForGBE() {
    // Hide button giftcard if operated by GlobalE
    if ($('#buttonGiftCardContainer').hasClass('isOperatedByGlobalE')) {
        $('#buttonGiftCardContainer').hide();
        $('#textGiftCardForGBE').show();
    }
}

// LOOKBOOK POSITION PLACEMENT
var element1 = $('.item_look:nth-of-type(31)');
var element2 = $('.item_look:nth-of-type(30)');
element1.insertBefore(element2);

if ($('.basket_gondole').length > 0) {
    setTimeout(function () {
        var look_main_swiper = new Swiper("#basket_gondole_slider", {
            centeredSlides: false,
            slidesPerView: 1.5,
            spaceBetween: 10,
            freeMode: true,
            scrollbar: '.swiper-scrollbar',
        });
    }, 600);
}

// LANG SWITCHER IN SIDEBAR
$("body").on("click", ".switchLangBtn", function () {
    $(".switchLangWrapper").slideToggle();
});

// Get product data
function getProductDetail(productId) {
    var form = document.getElementById('item_add_form');
    let checkColor = $("#prod_couleur option:selected", form).val();
    let checkSize = $("#prod_taille option:selected", form).val();
    let colorSelected = checkColor.length ? checkColor : "";
    let sizeSelected = checkSize.length ? checkSize : "";

    let data = {
        produitId: productId,
        colorId: colorSelected,
        sizeId: sizeSelected,
        json: 'json'
    };
    $.ajax({
        url: path_relative_root + 'ajax_product_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

// Function push into DataLayer
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}


// Get Object data
function getObjectDetail(type, id, currency) {
    var impressions = data_layer_products;
    let data = {
        type: type,
        id: id,
        json: 'json'
    };


    var dataInfo = {
        event: "productImpressions",
        ecommerce: {
            currencyCode: currency,
            impressions: [impressions]
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_object_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                dataInfo['ecommerce']['event_id'] = res.dataLayerData.event_id;
                pushIntoDatalayer(dataInfo);
            }
        }
    });
}

// when switching color on FP assoSlider not being centered.
var assoSwiperParams = {
    slidesPerView: 1.2,
    spaceBetween: 20,
    preloadImages: true,
    lazyLoading: true,
    loop: false,
    centeredSlides: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
};

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

function livraisonBf() {
    var now = Math.floor(Date.now() / 1000);

    if ($('#timestamp_check' ).length > 0) {
        now = $('#timestamp_check').attr('data-date');
    }

    // Timestamp 02/12/2024 00:01
    if (now < 1733094001) {
        $('.bloc_livraison.transporteur_colissimo .choix_delivery_postal .transporteur_delay').html( Translator.translate('transporteur_delay_1_bf'));
        $('.bloc_livraison.transporteur_colissimo .choix_delivery_relais .transporteur_delay').html( Translator.translate('transporteur_delay_39_bf'));
        $('.bloc_livraison.transporteur_livraison .choix_delivery_relais .transporteur_delay').html( Translator.translate('transporteur_delay_26_bf'));
    }
}

if ($('body').hasClass('step_2') && $('.wrapper_tunnel.livraison').length) {
    livraisonBf();
}